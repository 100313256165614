import React, { ReactNode, useContext } from 'react';
import { FormControl, makeStyles, Theme } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { GraySmall, NonHoverBorder, red } from 'src/theme/colors';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';
import RowDivider from 'src/legacy/components/RowDivider';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import {
  BaseTextField,
  BaseTextFieldProps,
  ResizableTextField,
} from 'src/legacy/components/TextField';
import {
  InputsTypes,
  OptionsInfo,
  QuestionAnswer,
} from 'src/legacy/components/FormsV2/formsTypes';
import { MultiChoicesSelectInput } from 'src/legacy/components/FormsV2/MultiChoicesSelectInput';
import { QuestionFileDropzone } from 'src/legacy/components/FormsV2/QuestionFileDropzone';
import { BaseChip, SeverityLevel } from 'src/legacy/components/UI';
import { RootState } from 'src/store';
import { AsteriskIcon } from 'src/legacy/components/FormsV2/AsteriskIcon';
import { RichTextDescription } from 'src/legacy/components/FormsV2/RichTextDescription';
import { useDebounceInput } from 'src/hooks/useDebounceInput';
import PhoneNumberInput from 'src/legacy/components/Inputs/PhoneNumberInput';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'src/constants';
import { Icon } from 'copilot-design-system';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormBuilderPreviewContext } from 'src/legacy/components/FormsV2/FormBuilderPreview';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '24px',
    border: `1px solid ${NonHoverBorder}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  // when question is required and the question is not answered, the question border will be red.
  redBorderCard: {
    border: `1px solid ${red}`,
  },
  // disabled input hover state border override
  disabledInput: {
    '&:hover  fieldset': {
      borderColor: `${NonHoverBorder} !important`,
    },
  },
}));

/**
 * @param questionConfigData: {QuestionType} The question configuration data.
 * @param questionIndex: {number} The question index is used to determine the question field values/errors states in the form.
 */
type QuestionAnswerCardProps = {
  questionAnswerData: QuestionAnswer;
  questionIndex: number;
  formId: string;
};

interface QuestionAnswerCardHeaderProps {
  title: ReactNode;
  description: string;
  type: InputsTypes;
  showRequiredAsterisk: boolean;
  showRequiredError: boolean;
  isQuestionEdited?: boolean;
}

/**
 * This component is responsible of rendering the question answer card header.
 * @param title: {string} The question title.
 * @param description: {string} The question description.
 * @param questionType: {InputsTypes} The question type. Used to determine the font type.
 * @returns
 */
export const QuestionAnswerCardHeader = ({
  title,
  description,
  type: questionType,
  showRequiredAsterisk,
  showRequiredError,
  isQuestionEdited,
}: QuestionAnswerCardHeaderProps) => {
  const classes = useStyles();
  const isTitleCard = questionType === InputsTypes.Title;
  const titleFontType = isTitleCard ? '24Medium' : '15Medium';
  const isClient = useSelector((state: RootState) => state.user.isClient);

  return (
    <div className={classes.header}>
      <div className="flex-1">
        <div className={classes.titleContainer}>
          <BaseTypography fontType={titleFontType}>{title} </BaseTypography>
          {/* show question deleted badge for internal user only  */}
          {!isClient && isQuestionEdited && (
            <BaseChip label="Question Edited" severity={SeverityLevel.high} />
          )}
        </div>
        <RichTextDescription description={description} />
      </div>

      <span>
        {showRequiredError && (
          <BaseTypography
            fontType="13Regular"
            component="span"
            style={{
              color: red,
              marginRight: '4px',
            }}
          >
            Input required
          </BaseTypography>
        )}
        {showRequiredAsterisk && (
          // when the question is required, a red asterisk is displayed
          <AsteriskIcon
            style={{
              fontSize: '12px',
            }}
          />
        )}
      </span>
    </div>
  );
};

const TextInputsTypes = [
  InputsTypes.ShortAnswer,
  InputsTypes.LongAnswer,
  InputsTypes.Email,
  InputsTypes.PhoneNumber,
];
export const MultiChoicesInputsTypes = [
  InputsTypes.SingleSelect,
  InputsTypes.MultiSelect,
];
const TextInputTypeToComponent = {
  [InputsTypes.ShortAnswer]: BaseTextField,
  [InputsTypes.LongAnswer]: ResizableTextField,
  [InputsTypes.PhoneNumber]: PhoneNumberInput,
  [InputsTypes.Email]: BaseTextField,
};

const TextInputTypePlaceholder = {
  [InputsTypes.ShortAnswer]: 'Click here to input...',
  [InputsTypes.LongAnswer]: 'Click here to input...',
  [InputsTypes.PhoneNumber]: '(718) 123-4567',
  [InputsTypes.Email]: 'example@example.com',
};

type QuestionAnswerInputProps = {
  type: InputsTypes;
  options?: OptionsInfo[];
  answerType?: 'single' | 'multi'; // used for multi choices input types
  questionId: string;
  readOnly?: boolean;
  identityId?: string;
  questionIndex: number;
} & Partial<BaseTextFieldProps>;

const isTextType = (
  type: InputsTypes,
): type is
  | InputsTypes.ShortAnswer
  | InputsTypes.LongAnswer
  | InputsTypes.Email
  | InputsTypes.PhoneNumber => TextInputsTypes.includes(type);

/**
 * This component is responsible of rendering the question answer input.
 * Based on the question type different input components are rendered.
 * e.g. for a question of type 'text' a text input component is rendered.
 * @returns {JSX.Element}  The question answer input.
 */
export const QuestionAnswerInput = ({
  name,
  type: questionType,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  options = [],
  questionId,
  readOnly = false,
  identityId,
  formId,
  questionIndex,
}: QuestionAnswerInputProps & {
  formId?: string;
}) => {
  const formikContext = useFormikContext<{
    questionAnswers: QuestionAnswer[];
  }>();
  const classes = useStyles();
  const [datePickerErr, setDatePickerErr] = React.useState<string>('');
  const [datePickerValue, setDatePickerValue] =
    React.useState<MaterialUiPickersDate | null>(null);
  const { isFormBuilderPreview } = useContext(FormBuilderPreviewContext);

  const handleDateChange = (date: MaterialUiPickersDate | null) => {
    setDatePickerValue(date);
    if (date && !date.isValid()) {
      setDatePickerErr('Enter a valid date.');
      return;
    } else if (!date) {
      formikContext.setFieldValue(
        `questionAnswers[${questionIndex}].responseValue`,
        '',
      );
    }

    if (date && date.isValid() && formikContext) {
      const valStr = moment(date).format(DATE_FORMAT);

      formikContext.setFieldValue(
        `questionAnswers[${questionIndex}].responseValue`,
        valStr,
      );
    }

    formikContext.setFieldError(
      `questionAnswers[${questionIndex}].responseValue`,
      '',
    );
    setDatePickerErr('');
  };

  const inputComponent = React.useMemo(() => {
    // if the question type is a text input type, render the appropriate input component
    // which would be either a BaseTextField or a ResizableTextField.
    if (isTextType(questionType)) {
      return (
        <TextField
          type={questionType}
          value={typeof value === 'string' ? value : ''}
          onChange={onChange ?? (() => {})}
          onBlur={onBlur}
          name={name}
          readOnly={readOnly}
          error={error}
          helperText={helperText}
        />
      );
    }

    if (questionType === InputsTypes.Date) {
      if (readOnly && !isFormBuilderPreview) {
        // on form response detail page, we don't show date picker but instead just show submitted date in text field
        return (
          <BaseTextField
            value={value}
            fullWidth
            variant="outlined"
            onBlur={onBlur}
            name={name}
            sizeVariant="medium"
            placeholder={DATE_FORMAT}
            disabled={readOnly}
            InputProps={{
              readOnly,
              classes: {
                disabled: classes.disabledInput,
              },
            }}
          />
        );
      } else {
        return (
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                disabled={readOnly}
                placeholder={DATE_FORMAT}
                TextFieldComponent={BaseTextField}
                name={`questionAnswers[${questionIndex}].responseValue`}
                margin="none"
                variant="inline"
                inputVariant="outlined"
                format={DATE_FORMAT}
                value={datePickerValue}
                error={error || Boolean(datePickerErr)}
                helperText={helperText || datePickerErr}
                onChange={handleDateChange}
                readOnly={readOnly}
                onBlur={onBlur}
                keyboardIcon={
                  <Icon
                    icon="Calender"
                    className={`w-[18px] h-[18px] text-[${GraySmall}]`}
                  />
                }
                InputAdornmentProps={{
                  style: {
                    position: 'absolute',
                    right: '0px', // container div already has the right padding
                  },
                }}
                autoOk
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        );
      }
    }

    // if the question type is a multi choices input type (single select or multi select)
    if (MultiChoicesInputsTypes.includes(questionType)) {
      return (
        <MultiChoicesSelectInput
          options={options}
          type={questionType}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value as string[]}
          readOnly={readOnly}
          questionIndex={questionIndex}
        />
      );
    }

    if (questionType === InputsTypes.FileUpload) {
      return (
        <QuestionFileDropzone
          questionId={questionId}
          fieldName={name || ''}
          value={value as string | string[]}
          readOnly={readOnly}
          identityId={identityId}
          formId={formId}
        />
      );
    }

    return null;
  }, [
    questionType,
    value,
    error,
    helperText,
    readOnly,
    options,
    datePickerValue,
    datePickerErr,
  ]);

  return inputComponent;
};

const TextField = ({
  name,
  value,
  onChange,
  onBlur,
  readOnly,
  type,
  error,
  helperText,
}: Required<Pick<QuestionAnswerInputProps, 'onChange'>> &
  Pick<QuestionAnswerInputProps, 'name' | 'onBlur' | 'readOnly'> & {
    value: string;
    type:
      | InputsTypes.LongAnswer
      | InputsTypes.ShortAnswer
      | InputsTypes.Email
      | InputsTypes.PhoneNumber;
    error?: boolean;
    helperText?: React.ReactNode;
  }) => {
  const classes = useStyles();
  const InputComponent = TextInputTypeToComponent[type];
  const [val, handleChange] = useDebounceInput(value, onChange);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> & string,
  ) => {
    if (event?.target) {
      handleChange(event);
    } else {
      const newEvent = {
        target: {
          name: name || '',
          value: event,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      handleChange(newEvent);
    }
  };

  return (
    <InputComponent
      value={val}
      fullWidth
      variant="outlined"
      onChange={handleInputChange}
      onBlur={onBlur}
      name={name}
      sizeVariant="medium"
      placeholder={TextInputTypePlaceholder[type]}
      disabled={readOnly}
      error={error}
      helperText={helperText}
      InputProps={{
        readOnly,
        classes: {
          disabled: classes.disabledInput,
        },
      }}
    />
  );
};

const inlineErrorQuestionTypes = [
  InputsTypes.ShortAnswer,
  InputsTypes.LongAnswer,
  InputsTypes.PhoneNumber,
  InputsTypes.Email,
  InputsTypes.Date,
];

/**
 * This component is responsible of rendering the question answers/responses
 * card. This is used in the form response submission page as well as the
 * form response view page.
 * @returns {JSX.Element}  The question answer card.
 */
export const QuestionAnswerCard = ({
  questionAnswerData,
  questionIndex,
  formId,
}: QuestionAnswerCardProps) => {
  const classes = useStyles();
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<{
      questionAnswers: QuestionAnswer[];
    }>();
  const {
    questionId,
    title: questionTitle,
    description,
    type: questionType,
    isRequired,
    options,
  } = questionAnswerData;
  const touch = getIn(
    touched,
    `questionAnswers[${questionIndex}].responseValue`,
  );
  const error = getIn(
    errors,
    `questionAnswers[${questionIndex}].responseValue`,
  );

  // required error is already shown inline with the field, so don't need to show in the header again
  const showRequiredError =
    isRequired &&
    touch &&
    error &&
    !inlineErrorQuestionTypes.includes(questionType);

  return (
    <RegularCardBox
      className={clsx(classes.root, {
        [classes.redBorderCard]: showRequiredError,
      })}
    >
      <QuestionAnswerCardHeader
        title={questionTitle}
        description={description ?? ''}
        type={questionType}
        showRequiredAsterisk={isRequired}
        showRequiredError={showRequiredError}
      />
      {questionType !== InputsTypes.Title && (
        <RowDivider mt={2.5} mb={0} noLine />
      )}
      <QuestionAnswerInput
        questionId={questionId}
        type={questionType}
        value={values.questionAnswers?.[questionIndex]?.responseValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(error && touch)}
        helperText={error && touch ? error : ''}
        name={`questionAnswers[${questionIndex}].responseValue`}
        questionIndex={questionIndex}
        options={options}
        formId={formId}
      />
    </RegularCardBox>
  );
};
