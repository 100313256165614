import React, { ComponentProps } from 'react';
import { Checkbox, InputAdornment } from '@material-ui/core';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteClassKey,
} from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { UserAvatar } from 'src/legacy/components/User';
import { ListboxComponent } from 'src/legacy/components/Select/ListBox';
import {
  BaseTextField,
  TextFieldSizeVariant,
} from 'src/legacy/components/TextField';
import { ChevronDownIcon } from 'src/legacy/components/Icons';
import { NonHoverBorder } from 'src/theme/colors';
import { BaseChip } from 'src/legacy/components/UI';
import { AvatarSizeType } from 'src/legacy/components/User/UserAvatar';
import clsx from 'clsx';
import {
  GroupListboxComponent,
  RenderGroup,
} from 'src/legacy/components/Select/GroupListBox';
import { useFormikContext } from 'formik';
import { Body } from 'copilot-design-system';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type channelMemberOptionTypes =
  | 'text'
  | 'client'
  | 'company'
  | 'title'
  | 'no-option';

// ComboBoxOption can be used for many types of multi-selects
// so it contains properties that other select can use
export type ComboBoxOption = {
  label: string;
  type: channelMemberOptionTypes | 'email';
  id: string;
  getstreamId?: string;
  avatar?: string;
  fallbackColor?: string;
  companyName?: string;
  group?: string;
  style?: React.CSSProperties;
  deleteIconStyle?: React.CSSProperties;
  isPlaceholder?: boolean;
};

export interface ChannelMemberSelectComboBoxOption extends ComboBoxOption {
  companyId?: string;
  type: channelMemberOptionTypes;
}

interface AdditionalComboBoxProps {
  openOnFocus?: boolean;
  inputProps?: InputProps;
}

interface InputProps {
  value?: string;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ComboBoxOwnProps {
  id: string;
  label: string;
  placeholder?: string;
  values: any[] | any;
  additionalComboBoxProps?: AdditionalComboBoxProps;
  withAvatars?: boolean;
  withChips?: boolean;
  showPlaceholder?: boolean;
  hideSelectedItems?: boolean;
  error?: boolean;
  helperText?: any;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  textFieldVariant?: TextFieldSizeVariant;
  disabledIDs?: string[];
  autoCompleteClasses?: Partial<Record<AutocompleteClassKey, string>>;
  avatarSize?: AvatarSizeType;
  open?: boolean; // Controls the open state of auto-complete
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  popupIcon?: React.ReactNode;
  noOptionsText?: string | React.ReactNode;
  closeIcon?: React.ReactNode;
  blurOnSelect?: boolean;
  fieldName?: string;
}

export type ComboBoxProps<
  Option extends ComboBoxOption = ComboBoxOption,
  Multiple extends boolean = true,
> = ComboBoxOwnProps &
  Pick<
    AutocompleteProps<Option, Multiple, Multiple, undefined>,
    'onChange' | 'options' | 'multiple' | 'groupBy'
  > &
  Pick<
    Partial<AutocompleteProps<Option, Multiple, Multiple, undefined>>,
    'filterOptions' | 'getOptionLabel'
  >;

function ComboBox<Option extends ComboBoxOption, Multiple extends boolean>({
  id,
  label,
  placeholder,
  showPlaceholder = true,
  options,
  values,
  onChange,
  onBlur,
  additionalComboBoxProps,
  filterOptions,
  withAvatars = false,
  avatarSize = 'mini',
  withChips = false,
  hideSelectedItems,
  multiple,
  error,
  helperText,
  disabled = false,
  textFieldVariant = 'skinny',
  disabledIDs = [],
  autoCompleteClasses = {},
  onKeyDown,
  open,
  popupIcon,
  groupBy,
  noOptionsText,
  closeIcon,
  blurOnSelect,
  fieldName,
}: ComboBoxProps<Option, Multiple>) {
  const { handleBlur } = useFormikContext();

  const getInputProps = (): InputProps => {
    if (additionalComboBoxProps) {
      if (additionalComboBoxProps.inputProps) {
        return additionalComboBoxProps.inputProps;
      }
    }
    return {};
  };

  const getOpenProps = () => (open !== undefined ? { open } : {});

  const CustomListboxComponent = React.forwardRef(
    (
      data: React.HTMLAttributes<HTMLDivElement>,
      ref: React.Ref<HTMLDivElement>,
    ) => {
      if (groupBy) {
        return <GroupListboxComponent ref={ref} {...data} />;
      } else {
        return <ListboxComponent ref={ref} {...data} />;
      }
    },
  );

  // openOnFocus indicates whether the dropdown list should open on focus or not
  const { openOnFocus = false } = additionalComboBoxProps || {};
  const getInputValueProp = () =>
    disabled && { inputValue: values ? values.label : '' };

  const selectedOptionsRenderer = (
    opts: ComboBoxOption[],
    getTagProps: any,
  ) => (
    <div className="flex flex-wrap gap-[5px] py-[5px] px-0">
      {opts.map((option: ComboBoxOption, index: number) => (
        <BaseChip
          classes={{
            root: 'rounded-full text-black-heading bg-gray-100 h-auto [&>span]:!p-0 m-0 border border-solid  border-non-hover-border py-1 pr-2 cursor-pointer hover:bg-secondary-hover',
            deleteIcon:
              'h-2 w-2 text-gray-small p-1 ml-0.5 hover:text-black-heading hover:bg-non-hover-border hover:rounded-full m-0',
            label: 'flex-shrink-0',
          }}
          label={
            <div className="flex items-center">
              {withAvatars && (
                <div
                  className={clsx({
                    'ml-2.5': option.type === 'company',
                    'ml-2': option.type === 'client',
                  })}
                >
                  <UserAvatar
                    shape={option.type === 'company' ? 'rounded' : 'circle'}
                    name={option.label}
                    avatarUrl={option.avatar}
                    fallbackColor={option.fallbackColor}
                    avatarSize="16small"
                    initialLetters={
                      option?.type === 'company'
                        ? option.label.split(' ')[0]
                        : ''
                    }
                    avatarStyles={{
                      border: `1px solid ${NonHoverBorder}`,
                      boxSizing: 'border-box',
                    }}
                  />
                </div>
              )}
              <div
                className={clsx({
                  'ml-2.5': !withAvatars,
                })}
              >
                <Body className="text-black-heading text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[300px]">
                  {option.label}
                </Body>
              </div>
            </div>
          }
          {...getTagProps({ index })}
          style={option.style ? option.style : {}}
          deleteIconStyle={option.deleteIconStyle || {}}
        />
      ))}
    </div>
  );

  const isOptionDisabled = (option: ComboBoxOption) => {
    return (
      option.type === 'title' ||
      option.type === 'no-option' ||
      disabledIDs.some((disabledID) => option.id.indexOf(disabledID) > -1)
    );
  };

  const optionItemRenderer = (
    option: ComboBoxOption,
    { selected }: { selected: ComponentProps<typeof Checkbox>['checked'] },
  ) => {
    const noOption = option.type === 'no-option';

    if (noOption) {
      return (
        // no-option is a disabled option, and thus gets 0.6 opacity
        // applied to it by MUI. We use a darker font to offset this.
        <Body className="text-black text-[12px] opacity-100">
          {`No matches found for "${option.label}"`}
        </Body>
      );
    }

    if (withChips) {
      return (
        <BaseChip
          label={option.label}
          style={option.style ? option.style : {}}
        />
      );
    }
    return (
      <div className="flex items-center w-full">
        {withAvatars && !noOption ? (
          <div className="mr-2">
            <UserAvatar
              type="image"
              primaryTextVariant="tableMain"
              name={option.label}
              avatarUrl={option.avatar}
              fallbackColor={option.fallbackColor}
              avatarSize={avatarSize}
              shape={option.type === 'company' ? 'rounded' : 'circle'}
              initialLetters={
                option?.type === 'company' ? option.label.split(' ')[0] : ''
              }
              avatarStyles={{
                border: `1px solid ${NonHoverBorder}`,
                boxSizing: 'border-box',
              }}
            />
          </div>
        ) : (
          <>
            {!noOption && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
          </>
        )}

        <div>
          <Body className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[360px] text-black-heading text-[14px]">
            {option.label}
          </Body>
          {option.companyName && (
            <Body className="text-black-heading text-[14px]">
              {option.companyName || ''}
            </Body>
          )}
        </div>
      </div>
    );
  };

  const inputRenderer: ComponentProps<typeof Autocomplete>['renderInput'] = (
    params,
  ) => {
    if (values?.label) {
      params.InputProps.startAdornment = (
        <>
          <InputAdornment position="start" className="ml-[5px] mr-0">
            <UserAvatar
              type="image"
              primaryTextVariant="tableMain"
              name={values.label}
              avatarUrl={values.avatar}
              fallbackColor={values.fallbackColor}
              avatarSize={avatarSize}
              shape={values.type === 'company' ? 'rounded' : 'circle'}
              initialLetters={
                values?.type === 'company' ? values.label.split(' ')[0] : ''
              }
              avatarStyles={{
                border: `1px solid ${NonHoverBorder}`,
                boxSizing: 'border-box',
              }}
            />
          </InputAdornment>

          {params.InputProps.startAdornment}
        </>
      );
    }

    return (
      <BaseTextField
        sizeVariant={textFieldVariant}
        {...params}
        label={label}
        name={fieldName}
        variant="outlined"
        placeholder={showPlaceholder ? placeholder || `Choose ${label}` : ''}
        fullWidth
        {...getInputProps()}
        error={error}
        helperText={helperText}
        disabled={disabled}
        inputProps={{
          ...params.inputProps,
          isautocomplete: 'true',
        }}
        InputLabelProps={{
          classes: {
            root: clsx({ '!text-non-hover-border': disabled }),
          },
        }}
        onBlur={handleBlur}
      />
    );
  };

  return (
    <Autocomplete<Option, Multiple, Multiple>
      blurOnSelect={blurOnSelect}
      limitTags={2}
      classes={{
        listbox: 'py-1.5 px-0 ',
        paper:
          'border border-solid border-non-hover-border shadow-dropdown-menu',
        groupLabel: 'text-gray-small font-medium',
        input: clsx(
          'placeholder:text-[#9B9FA3] [&&&&::placeholder]:!opacity-100',
          {
            'text-[14px]': true,
            'text-hover-border': disabled,
            'text-gray-400 text-[16px] sm:text-[13px]': false,
          },
        ),
        endAdornment: 'mr-0.5',

        ...autoCompleteClasses,
      }}
      multiple={multiple}
      id={id}
      options={options}
      value={values}
      {...getInputValueProp()}
      {...getOpenProps()}
      onBlur={onBlur}
      filterOptions={filterOptions}
      onChange={onChange}
      onKeyDown={onKeyDown}
      popupIcon={
        popupIcon !== undefined ? (
          popupIcon
        ) : (
          <ChevronDownIcon
            className={clsx('h-[15px] w-[15px]', {
              'text-non-hover-border': disabled,
              'text-gray-small': !disabled,
            })}
          />
        )
      }
      ListboxComponent={CustomListboxComponent}
      renderGroup={RenderGroup}
      groupBy={groupBy}
      // to shrink row size for color chips list or small avatars
      ListboxProps={{
        chipsized: String(withChips || avatarSize === '18small'),
      }}
      renderTags={selectedOptionsRenderer}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option: ComboBoxOption) =>
        multiple
          ? values.find((item: any) => item.id === option.id)
          : values.id === option.id
      }
      renderOption={optionItemRenderer}
      renderInput={inputRenderer}
      disableClearable={multiple}
      openOnFocus={openOnFocus}
      closeIcon={closeIcon}
      filterSelectedOptions={hideSelectedItems}
      disabled={disabled}
      getOptionDisabled={(option) => isOptionDisabled(option)}
      noOptionsText={noOptionsText}
    />
  );
}

export default ComboBox;
