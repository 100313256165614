import React, { forwardRef } from 'react';
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { drawerWidth } from 'src/legacy/components/Sidebar/DrawerSection';
import { NotAllowedPage } from 'src/legacy/components/UI/NotAllowedPage';
import { NetworkErrorPage } from 'src/legacy/components/UI/Pages/NetworkErrorPage';
import { RootState } from 'src/store';
import { LoadingPage } from 'src/legacy/components/UI/Pages/LoadingPage';
import { SettingsPageContent } from 'src/legacy/components/Settings/SettingsPageContent';
import { useGetPortalConfigQuery } from 'src/services/api';
import { RouteContext } from 'src/context';
import { HomePageContent } from 'src/legacy/components/HomePage';
import { useAppSelector } from 'src/hooks/useStore';
import { shallowEqual } from 'react-redux';
import { ensureApiError } from 'src/utils/Errors';
import { MfaEnforcementPage } from 'src/legacy/components/UI/Pages/MfaEnforcementPage';

export interface PageContentStyleProps {
  noSpace?: boolean;
  fullMobileWidth?: boolean;
  maxWidth?: string;
  noToolbar?: boolean;
}

export interface PageContentProps extends PageContentStyleProps {
  children?: React.ReactNode;
  allowed?: boolean;
  fullPage?: boolean;
  isSettingsPage?: boolean;
}
const getLeftPadding = (
  props: PageContentStyleProps,
  theme: Theme,
  isSmScreen: boolean,
) => {
  const additionalPadding = !isSmScreen ? drawerWidth : 0;
  return `${additionalPadding + (props.noSpace ? 0 : theme.spacing(4.5))}px`;
};

const usePageContentStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'auto',
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        padding: (props: PageContentStyleProps) =>
          props.noSpace ? 0 : theme.spacing(0, 2),
      },
      [theme.breakpoints.up('sm')]: {
        height: '100%',
        padding: (props: PageContentStyleProps) =>
          props.noSpace ? 0 : theme.spacing(0, 4.5),
        paddingLeft: (props: PageContentStyleProps) =>
          getLeftPadding(props, theme, true),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: (props: PageContentStyleProps) =>
          getLeftPadding(props, theme, false),
      },
    },
    noSpaceContainer: {
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    noToolbar: (props: PageContentStyleProps) => ({
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingTop: props.noSpace ? 0 : theme.spacing(2),
      },
    }),
    pageContentBody: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        padding: 'initial',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
      },
      width: '100%',
      height: '100%',
      display: 'flex',
      margin: 'initial',
      flexDirection: 'column',
    },
    fullPage: {
      padding: 0,
      width: '100%',
    },
  }),
);

export const PageContent = forwardRef<HTMLDivElement, PageContentProps>(
  (
    {
      children,
      allowed = true,
      noToolbar = false,
      fullPage = false,
      isSettingsPage = false,
      ...styleProps
    },
    ref,
  ) => {
    const { isLoading: isPortalConfigurationLoading, error } =
      useGetPortalConfigQuery(); // Check whether portal configuration is loaded
    const portalConfigError = ensureApiError(error);

    const { loadingError, permissions } = useAppSelector(
      (state: RootState) => ({
        loadingError: state.user.loadingError,
        permissions: state.user.data?.permissions,
      }),
      shallowEqual,
    );
    const { pathname } = React.useContext(RouteContext);
    const isClientUser = useAppSelector((state) => state.user.isClient);
    const classes = usePageContentStyles({
      ...styleProps,
      noToolbar,
    });
    const redirectToHomePage =
      (pathname === '/' || pathname.startsWith('/portal/')) && !isClientUser;

    let PageOverrideComponent;
    if (portalConfigError.code === 'mfa_required') {
      PageOverrideComponent = MfaEnforcementPage;
    } else if (loadingError) {
      PageOverrideComponent = NetworkErrorPage;
    } else if (isPortalConfigurationLoading || !permissions) {
      PageOverrideComponent = LoadingPage;
    } else if (!allowed) {
      PageOverrideComponent = NotAllowedPage;
    }

    const noSpaceContainerProps = {
      className: classNames(classes.root, classes.noSpaceContainer, {
        [classes.fullPage]: noToolbar,
      }),
      width: 1,
      ref,
    };

    if (PageOverrideComponent) {
      return (
        <Box {...noSpaceContainerProps} display="flex">
          <PageOverrideComponent />
        </Box>
      );
    }

    if (isSettingsPage) {
      return <SettingsPageContent>{children}</SettingsPageContent>;
    }

    if (fullPage) {
      return (
        <div className={classes.fullPage} ref={ref}>
          {children}
        </div>
      );
    }

    if (styleProps.noSpace) {
      return <Box {...noSpaceContainerProps}>{children}</Box>;
    }
    return (
      <Grid
        container
        className={classNames(classes.root, {
          [classes.noToolbar]: noToolbar,
        })}
        ref={ref}
      >
        <Grid className={classes.pageContentBody} item>
          {redirectToHomePage ? <HomePageContent /> : children}
        </Grid>
      </Grid>
    );
  },
);
