import React from 'react';
import * as Constants from 'src/constants';
import { GENERAL_OPERATIONS } from 'src/constants';
import { FlagsContext } from 'src/context';
import { useInProductNotificationsState } from 'src/hooks/useInProductNotificationsState';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { useAppSelector } from 'src/hooks/useStore';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';
import { RootState } from 'src/store';
import * as UserUtils from 'src/utils/UserUtils';

export const useRouteAccess = () => {
  const planStatus = usePlanStatus();
  const isUnAuth = useAppSelector((state: RootState) => state.user.isUnAuth);
  const isClient = useAppSelector((state: RootState) => state.user.isClient);

  const { EnablePaymentLinksManagement } = React.useContext(FlagsContext);

  const permissions = useAppSelector(
    (state: RootState) => state.user.data?.permissions,
  );

  const { data: { moduleSettings } = {} } = useGetInstallsQuery();

  const { isInboxInProductNotificationsDisabled } =
    useInProductNotificationsState();

  /* Since portalConfig object is not updated in real time, we need
   * to get the enable module state from the moduleSettings.
   * This method gets default-module active state from module settings.
   * @param moduleId: default module id
   * @returns
   */
  const getDefaultModuleStateFromModuleSettings = (moduleId: string) => {
    if (!moduleSettings || !moduleSettings.length) return true;
    const moduleItem = moduleSettings.find((mod) => moduleId === mod.id);
    if (!moduleItem) {
      return false;
    }

    if (moduleItem.disabled) {
      return false;
    }
    return true;
  };

  return {
    allowProfilePage: !isUnAuth,
    allowCRMPages: UserUtils.hasPermission(Constants.PERM_MOD_CRM, permissions),
    allowHomePage: !isClient,
    allowMessagesPage:
      UserUtils.hasPermission(Constants.PERM_MOD_MESSAGES, permissions) &&
      getDefaultModuleStateFromModuleSettings('messages'),
    allowFilesPage:
      UserUtils.hasPermission(Constants.PERM_MOD_FILES, permissions) &&
      getDefaultModuleStateFromModuleSettings('files'),
    allowPaymentsPage:
      getDefaultModuleStateFromModuleSettings('payments') &&
      UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions),
    allowKnowledgeBasePage:
      UserUtils.hasPermission(Constants.PERM_MOD_KNOWLEDGE_BASE, permissions) &&
      getDefaultModuleStateFromModuleSettings('knowledgeBase'),
    allowSubscriptionPage:
      !isUnAuth &&
      !isClient &&
      (UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions) ||
        getDefaultModuleStateFromModuleSettings('payments')),
    // settings page access configurations
    allowSettingsCustomizationPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_CUSTOMIZATION,
      permissions,
    ),
    // Settings/payments page is only visible for clients
    // when permission is allowed and the payments module is enabled in portalConfig,
    allowSettingsBillingPage:
      isClient &&
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_BILLING,
        permissions,
      ) &&
      getDefaultModuleStateFromModuleSettings('payments'),
    allowSettingsModuleManagement: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MODULE_MANAGEMENT,
      permissions,
    ),
    allowSettingsFiles:
      UserUtils.hasPermission(Constants.PERM_MOD_SETTINGS_FILES, permissions) &&
      getDefaultModuleStateFromModuleSettings('files'),
    allowSettingsModulePayments:
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_MODULE_PAYMENTS,
        permissions,
      ) && getDefaultModuleStateFromModuleSettings('payments'),
    allowSettingsMainPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MAIN_PG,
      permissions,
    ),
    allowSettingsModuleMessaging:
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_MESSAGING,
        permissions,
      ) && getDefaultModuleStateFromModuleSettings('messages'),
    allowSettingsMembersPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MEMBERS,
      permissions,
    ),
    allowSettingsApiPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_API,
      permissions,
    ),
    allowSettingsAppsPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_APPS,
      permissions,
    ),
    allowSettingsExtensionsPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_EXTENSIONS,
      permissions,
    ),
    allowSettingsDomainPage: UserUtils.hasOperation(
      Constants.PERM_MOD_SETTINGS_DOMAINS,
      GENERAL_OPERATIONS.UPDATE,
      permissions,
    ),
    allowFormsInternal:
      getDefaultModuleStateFromModuleSettings('forms') &&
      UserUtils.hasPermission(Constants.PERM_MOD_FORMS, permissions) &&
      !isClient,
    allowFormsClient:
      getDefaultModuleStateFromModuleSettings('forms') &&
      UserUtils.hasPermission(Constants.PERM_MOD_FORMS, permissions),
    allowClientDetailsPage: !isClient,
    allowCompaniesDetailsPage: !isClient,
    allowSettingsPlanPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_PLANS,
      permissions,
    ),
    allowSettingsReferralPage: !isClient && !planStatus.isTrialing,
    allowReferralPage: !isClient && !planStatus.isTrialing,
    allowExtensionsPage: true,
    allowNotificationSettings: !isClient,
    allowClientImportPage: !isClient,
    allowInboxPage: !isClient && !isInboxInProductNotificationsDisabled,
    allowAppSetupPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MODULE_MANAGEMENT,
      permissions,
    ),
    allowProductsPage: true,
    allowContractsPage: getDefaultModuleStateFromModuleSettings('contracts'),
    allowAutomationsPage: !isClient,
    allowInvoices:
      getDefaultModuleStateFromModuleSettings('payments') &&
      UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions),
    allowInvoiceDetailsPage:
      !isClient &&
      getDefaultModuleStateFromModuleSettings('payments') &&
      UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions),
    allowPaymentLinks:
      EnablePaymentLinksManagement &&
      !isClient &&
      getDefaultModuleStateFromModuleSettings('payments') &&
      UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions),
  } as const;
};
