import React, { useEffect, useContext } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { makeStyles, createStyles, AppBar } from '@material-ui/core';
import flagsmith from 'flagsmith';
import * as Sentry from '@sentry/react';
import { PortalConfigContext, RouteContext } from 'src/context';
import { RootState } from 'src/store/reduxTypes';
import * as Colors from 'src/theme/colors';
import {
  togglePrimarySidebarMobile,
  updateSearchValueAction,
} from 'src/store/ui/actions';
import { ShortcutsSidebar } from 'src/legacy/components/ShortcutsDrawer';
// external-global styles must be imported in your JS.
import s from 'src/legacy/components/Layout/Layout.css';
import {
  DESKTOP_APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
  BANNER_HEIGHT,
} from 'src/constants/uiConsts';
import Navbar from 'src/legacy/components/Navbar';
import Sidebar from 'src/legacy/components/Sidebar';
import AuthModal from 'src/legacy/components/Auth/AuthModal';
import { HelpCenterModal } from 'src/legacy/components/Layout/HelpCenterModal';
import { CompleteExternalActionModal } from 'src/legacy/components/Modals';
import { useAnonymousId } from 'src/hooks/useAnonymousId';
import { CommandBar } from 'src/legacy/components/CommandBar';
import { GeneralCommandbarActionsInitializer } from 'src/legacy/components/CommandBar/GeneralCommandbarActionsInitializer';
import { useImportProgressNotifier } from 'src/hooks/useImportProgressNotifier';
import { CLIENT_DETAILS_PAGE, CRM_CLIENTS_PAGE } from 'src/constants';
import { useGetUserDataQueries } from 'src/hooks/useGetUserDataQueries';
import { initializeOnboardingTestData } from 'src/store/user/actions';
import { Banner } from 'src/legacy/components/Banner';
import { drawerWidth } from 'src/legacy/components/Sidebar/SidebarContainer';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { shallowEqual } from 'react-redux';
import { WhatsNew } from 'src/legacy/components/WhatsNew/WhatsNew';

interface SpacingProps {
  disableGutters: boolean;
  showBanner: boolean;
}

export const SCROLLBAR_SIZE = 10;

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      html: {
        'scrollbar-width': 'none',
        'scrollbar-color': 'transparent transparent',
      },

      '::-webkit-scrollbar': {
        width: `${SCROLLBAR_SIZE}px`,
        height: `${SCROLLBAR_SIZE}px`, // horizontal scroll height
        backgroundColor: 'transparent',
      },
      '::-webkit-scrollbar-track': {
        '-webkit-border-radius': '10px',
        borderRadius: '10px',
        backgroundColor: 'transparent',
      },

      '::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': '10px',
        'border-radius': '10px',
        border: '2px solid #fff',
        transition: 'all 0.3s ease-in-out',
        backgroundColor: 'transparent',
      },
      '& .on-scrollbar': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.ScrollbarColor,
        },
        'scrollbar-color': `${Colors.ScrollbarColor} transparent`,
        'scrollbar-width': 'auto',
      },
    },
    appBar: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      boxShadow: 'none',
      zIndex: theme.zIndex.drawer + 1,
      left: '50%',
      transform: 'translateX(-50%)',
      borderBottom: `1px solid ${Colors.DividersAndCardBorders}`,
      [theme.breakpoints.down('xs')]: {
        height: (props: SpacingProps) =>
          `${
            (props.disableGutters ? 0 : MOBILE_APP_BAR_HEIGHT) +
            (props.showBanner ? BANNER_HEIGHT : 0)
          }px`,
      },
      [theme.breakpoints.up('md')]: {
        height: (props: SpacingProps) =>
          `${
            (props.disableGutters ? 0 : DESKTOP_APP_BAR_HEIGHT) +
            (props.showBanner ? BANNER_HEIGHT : 0)
          }px`,
        paddingLeft: drawerWidth,
      },
    },
    root: {
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      overflowY: 'auto',
      boxSizing: 'border-box',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: (props: SpacingProps) =>
          props.disableGutters
            ? // when page is full space, we also need to consider
              // leaving spacing for the trial banner.
              `${props.showBanner ? BANNER_HEIGHT : 0}px`
            : `${
                DESKTOP_APP_BAR_HEIGHT + (props.showBanner ? BANNER_HEIGHT : 0)
              }px`,
      },
      [theme.breakpoints.down('xs')]: {
        overflowY: 'hidden',
        '@supports ( -moz-appearance:none )': {
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        },
        paddingTop: (props: SpacingProps) =>
          props.disableGutters
            ? `${props.showBanner ? BANNER_HEIGHT : 0}px`
            : `${
                MOBILE_APP_BAR_HEIGHT + (props.showBanner ? BANNER_HEIGHT : 0)
              }px`,
      },
    },
  }),
);

export interface LayoutProps {
  title?: string;
  hasNavbar?: boolean;
  showSearchbar?: boolean;
  showBreadCrumb?: boolean;
  BreadCrumbComponent?: React.FC;
  disableGutters?: boolean;
  hasSidebar?: boolean;
  showNavbarAuthActions?: boolean;
  headerComponent?: React.ReactNode;
}

const PAGES_WITH_RETAINED_SEARCH = [
  CRM_CLIENTS_PAGE.path,
  CLIENT_DETAILS_PAGE.path,
];

const Layout: React.FC<LayoutProps> = (props) => {
  const {
    title,
    hasNavbar = true,
    showSearchbar = false,
    showBreadCrumb = false,
    BreadCrumbComponent,
    hasSidebar = true,
    children,
    disableGutters = false,
    showNavbarAuthActions = true,
    headerComponent,
  } = props;
  const layoutWrapperRef = React.useRef<HTMLDivElement>(null);
  const portalConfig = useContext(PortalConfigContext);
  const { pathname } = useContext(RouteContext);
  const dispatch = useAppDispatch();
  const savedAnonymousId = useAnonymousId();
  const {
    id,
    loaded: userLoaded,
    loading: userLoading,
    loadingError: userLoadingError,
    isClient,
    userCognitoInstanceAttributes,
    userId,
  } = useAppSelector(
    (state: RootState) => ({
      id: state.user.id,
      loaded: state.user.loaded,
      loading: state.user.loading,
      loadingError: state.user.loadingError,
      isClient: state.user.isClient,
      userCognitoInstanceAttributes: state.user.instance?.attributes,
      userId: state.user.data?.userId,
    }),
    shallowEqual,
  );
  const isPrimarySideBarMobileOpen = useAppSelector(
    (state: RootState) => state.ui.primarySideBarMobile.isOpen,
  );

  const bannerOptions = useAppSelector(
    (state: RootState) => state.ui.bannerOptions,
  );

  const classes = useStyles({
    disableGutters,
    showBanner: Boolean(bannerOptions),
  });

  const showPrimarySidebarMobile = React.useCallback(() => {
    dispatch(togglePrimarySidebarMobile({ isOpen: true }));
  }, []);

  const hidePrimarySidebarMobile = React.useCallback(() => {
    dispatch(togglePrimarySidebarMobile({ isOpen: false }));
  }, []);

  useGetUserDataQueries(userLoaded);

  // this hook is used to notify user about import progress
  useImportProgressNotifier();

  // // this replaces component did mount hook
  useEffect(() => {
    if (userLoaded && id) {
      // initialize onboarding test data
      if (!isClient) {
        initializeOnboardingTestData();
      }

      // trigger identify calls

      if (userCognitoInstanceAttributes) {
        if (analytics && analytics.identify && analytics.group) {
          if (savedAnonymousId) {
            analytics.setAnonymousId(savedAnonymousId);
          }

          analytics.identify(
            id,
            {
              name: `${userCognitoInstanceAttributes.given_name} ${userCognitoInstanceAttributes.family_name}`,
              email: userCognitoInstanceAttributes.email,
              userType: isClient ? 'client' : 'internal',
              portalURL: window.location.origin,
            },
            {
              integrations: {
                'Customer.io': !isClient,
              },
            },
          );
          analytics.group(
            portalConfig.portalHeader,
            {
              groupType: 'Portal',
              name: portalConfig.name,
            },
            {
              integrations: {
                'Customer.io': !isClient,
                'Customer.io Actions': !isClient,
              },
            },
          );
        }
        if (userId) {
          flagsmith.identify(userId || '');
        }
        flagsmith.setTrait('portal_id', portalConfig.portalHeader);
        if (userCognitoInstanceAttributes.email) {
          flagsmith.setTrait('email', userCognitoInstanceAttributes.email);
        }
        Sentry.setUser({
          id,
          email: userCognitoInstanceAttributes.email,
          segment: portalConfig.portalHeader,
        });
      }
    }
  }, [id, userLoaded, userLoading, userLoadingError]);

  /**
   * Search bar should persist the search query when navigating between client => client details and vice versa.
   * Resets the search bar if history stack does not indicate that navigation occurred between client and client detail.
   * Logic resides in Layout component because `Searchbox` component isn't always preset in DOM
   */
  React.useEffect(() => {
    if (!PAGES_WITH_RETAINED_SEARCH.includes(pathname)) {
      dispatch(updateSearchValueAction(''));
    }
    return () => {
      if (!PAGES_WITH_RETAINED_SEARCH.includes(pathname)) {
        dispatch(updateSearchValueAction(''));
      }
    };
  }, [pathname]);

  React.useEffect(() => {
    if (layoutWrapperRef.current) {
      layoutWrapperRef.current.scrollTop = 0;
    }
  }, [children]);

  return (
    <div className={classes.root} ref={layoutWrapperRef}>
      <CommandBar>
        <AppBar position="fixed" className={classes.appBar}>
          {bannerOptions ? <Banner {...bannerOptions} /> : null}
          {hasNavbar && (
            <Navbar
              title={title}
              userLoaded={userLoaded}
              isClient={isClient}
              userLoading={userLoading}
              onOpenSidebarMobile={showPrimarySidebarMobile}
              showSearchbar={showSearchbar}
              showBreadCrumb={showBreadCrumb}
              BreadCrumbComponent={BreadCrumbComponent}
              showNavbarAuthActions={showNavbarAuthActions}
              headerContent={headerComponent}
            />
          )}
        </AppBar>
        <HelpCenterModal />
        {hasSidebar && (
          <Sidebar
            onMobileClose={hidePrimarySidebarMobile}
            openMobile={isPrimarySideBarMobileOpen}
          />
        )}
        {children}
        <AuthModal />
        {userLoaded && (
          <>
            <CompleteExternalActionModal />
            <GeneralCommandbarActionsInitializer />
            <WhatsNew />
          </>
        )}
        <ShortcutsSidebar />
      </CommandBar>
    </div>
  );
};

export default withStyles(s)(Layout);
